import { useState, forwardRef, useImperativeHandle, ChangeEvent } from "react";
import { Status } from "../../features/notifications/notificationSlice";
import useGetLanguage from "../../utils/useGetLanguage";
import { LanguageText } from "../../dictionary/GroupNotificationText";
export interface NotificationStatusProps {
  status?: Status;
}
export interface NotificationStatusRef {
  getNotificationStatus: () => NotificationStatusProps;
  validate: () => boolean;
}
const NotificationStatus = forwardRef(
  ({ status }: NotificationStatusProps, ref): JSX.Element => {
    const [error, setError] = useState("");
    const [notificationStatus, setNotificationStatus] = useState<Status>(
      status || {
        active: true,
        current: false,
        triggered_at: new Date(),
        last_trigger: new Date(),
        evaluated_at: new Date(),
      }
    );

    const language: LanguageText | null = useGetLanguage({
      fileName: "GroupNotificationText",
    });

    useImperativeHandle(ref, () => ({
      getNotificationStatus: () => ({
        status: notificationStatus,
      }),
      validate: () => {
        // if (!notificationName || notificationName.trim() === "") {
        //   setError("Name cannot be empty");
        //   return false;
        // }
        // if (!/^[a-zA-Z0-9 ]+$/.test(notificationName)) {
        //   setError("Name contains invalid characters");
        //   return false;
        // }
        // setError("");
        return true;
      },
    }));
    const handleActiveChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { name, checked } = event.target;
      setNotificationStatus((prev) => ({
        ...prev,
        active: checked,
      }));
    };

    return (
      // <>
      //   <h1 className="text-sm font-semibold text-left text-gray-800 leading-tight mb-2">
      //     {language?.statusComponent?.heading || "Status"}
      //   </h1>
      //   <div className="w-full flex items-center p-0">
      //     <input
      //       type="checkbox"
      //       name="active"
      //       checked={notificationStatus.active}
      //       onChange={handleActiveChange}
      //       className="form-checkbox h-5 w-5 text-blue-600 focus:ring-blue-500 rounded mr-2"
      //     />
      //     <label className="text-gray-700">
      //       {language?.statusComponent?.active || "Active"}
      //     </label>
      //   </div>
      // </>

      <>
        <h1 className="text-sm font-semibold text-left text-gray-800 leading-tight mb-2">
          {language?.statusComponent?.heading || "Status"}
        </h1>
        <div className="w-full flex items-center p-0">
          <div className="flex items-center mr-4">
            <input
              type="checkbox"
              name="active"
              checked={notificationStatus.active}
              onChange={handleActiveChange}
              className="form-checkbox h-5 w-5 text-blue-600 focus:ring-blue-500 rounded mr-2"
            />
            <label className="text-gray-700">
              {language?.statusComponent?.active || "Active"}
            </label>
          </div>
          {/* <div className="flex items-center">
            <input
              type="checkbox"
              name="current"
              checked={false}
              className="form-checkbox h-5 w-5 text-blue-600 focus:ring-blue-500 rounded mr-2"
            />
            <label className="text-gray-700">Current</label>
          </div> */}
        </div>
      </>
    );
  }
);

export default NotificationStatus;
