import React, { useState, forwardRef, useImperativeHandle, ChangeEvent } from "react";
import {
  Operators,
  HysteresisMode,
  Rule,
} from "../../features/notifications/notificationSlice";
import { CiCircleRemove } from "react-icons/ci";
import { useAppSelector } from "../../app/hooks";
import { Device, Devices, selectDevices } from "../../features/devices/deviceSlice";
import { useParams } from "react-router-dom";
import { generateBsonObjectId } from "../../utils/GroupNotificationUtils";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { MdOutlineError } from "react-icons/md";
import { toast } from "react-toastify";
import { LanguageText } from "../../dictionary/GroupNotificationText";
import useGetLanguage from "../../utils/useGetLanguage";
export interface NotificationRuleProps {
  notificationRules?: Rule[];
  rules?: Rule[];
}
export interface NotificationRuleRef {
  validate: () => boolean;
  getNotificationRule: () => NotificationRuleProps;
}
export interface RuleProps {
  ruleProp?: Partial<Rule>;
}

interface RuleFormProps {
  rule?: Rule;
  onSave: (rule: Rule, isValid: boolean) => boolean;
}

const NotificationRule = forwardRef(
  ({ notificationRules }: NotificationRuleProps, ref) => {
    const [rules, setRules] = useState<Rule[]>(
      notificationRules || [
        {
          id: generateBsonObjectId(),
          spec: {
            device_eui: "",
            parameter: "",
            operator: "-1",
            threshold: "",
            hysteresis: {
              value: 0,
              mode: 0,
            },
          },
          isSaved: false,
        },
      ]
    );

    const language: LanguageText | null = useGetLanguage({
      fileName: "GroupNotificationText",
    });
    const { groupid } = useParams();
    const groupDevices: Devices = useAppSelector(selectDevices).devices.filter(
      (device) => device.group_id === groupid
    );

    const saveRule = (newRule: Rule, isValid: boolean): boolean => {
      if (!isValid) return false;
      newRule.isSaved = true; // temporal solution. To be reviewed
      const newRules = rules.map((rule) =>
        rule.id === newRule.id ? { ...rule, ...newRule } : rule
      );
      setRules(newRules);
      return true;
    };

    const addRule = () => {
      const errorMessage =
        language?.messages?.toast?.rule?.invalidRule || "Error in rule";
      const lastRule = rules[rules.length - 1];
      if (
        lastRule?.id === "" ||
        lastRule?.spec?.device_eui === "" ||
        lastRule?.spec?.parameter === ""
      ) {
        toast.error(errorMessage);
        return;
      }
      setRules((prev) => {
        return [
          ...prev,
          {
            id: generateBsonObjectId(),
            spec: {
              device_eui: "",
              parameter: "",
              operator: "-1",
              threshold: "",
              hysteresis: {
                value: 0,
                mode: 0,
              },
            },
            isSaved: false,
          },
        ];
      });
    };
    const removeRule = (ruleId: string) => {
      setRules((prev) => {
        return [...prev.filter((rule) => rule.id !== ruleId)];
      });
    };

    useImperativeHandle(ref, () => ({
      validate: (): boolean => {
        let isValid = true;
        if (rules.length <= 0) {
          isValid = false;
          toast.error(
            language?.messages?.toast?.rule?.minRules ||
              "You must add rules a minimum of one rule"
          );
        }
        rules.forEach((rule: Rule) => {
          if (!rule.id || !rule.spec?.device_eui || !rule.spec.parameter) {
            isValid = false;
            toast.error(language?.messages?.toast?.rule?.invalidRule || "Error in rules");
          }
        });
        return isValid;
      },
      getNotificationRule: () => ({
        rules: rules.map((rule) => {
          const { isSaved, ...restOfRule } = rule;
          return restOfRule;
        }),
      }),
    }));

    const RuleForm = ({ rule, onSave }: RuleFormProps): JSX.Element => {
      const [isRuleSaved, setIsRuleSaved] = useState<boolean>(rule?.isSaved ?? true);
      const ruleId = rule?.id;
      const [deviceEUI, setDeviceEUI] = useState<string>(rule?.spec?.device_eui || "");
      const [parameter, setParameter] = useState<string>(rule?.spec?.parameter || "");
      const [operator, setOperator] = useState<number | string>(
        rule?.spec?.operator ?? "-1"
      );
      const [threshold, setThreshold] = useState<number | string>(
        rule?.spec?.threshold || ""
      );
      const [hysteresisMode, setHysteresisMode] = useState<number>(
        rule?.spec?.hysteresis?.mode || 0
      );

      const [hysteresisValue, setHysteresisValue] = useState<number | string>(
        rule?.spec?.hysteresis?.value || ""
      );

      const validMagThreshold: boolean = parameter.startsWith("sw_") && threshold === "";
      const validNonMagThreshold: boolean =
        !parameter.startsWith("sw_") && threshold !== "";
      const validThreshold: boolean = validMagThreshold || validNonMagThreshold;
      const validOperator: boolean = Number(operator) >= 0 && Number(operator) <= 8;
      const validHysteresis: boolean =
        hysteresisMode !== 0 && hysteresisValue === "" ? false : true;
      const isValidRule: boolean =
        ruleId !== "" &&
        deviceEUI !== "" &&
        parameter !== "" &&
        validOperator !== false &&
        validThreshold !== false &&
        validHysteresis !== false;

      const handleSaveRule = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        if (!isValidRule) {
          toast.error(language?.messages?.toast?.rule?.invalidRule || "Error in rules");
          return;
        }
        let rule;
        parameter.startsWith("sw_")
          ? (rule = {
              id: ruleId,
              spec: {
                device_eui: deviceEUI,
                parameter: parameter,
                operator: operator,
              },
              isSaved: isRuleSaved,
            })
          : (rule = {
              id: ruleId,
              spec: {
                device_eui: deviceEUI,
                parameter: parameter,
                operator: operator,
                threshold: Number(threshold),
                hysteresis: {
                  mode: hysteresisMode,
                  value: Number(hysteresisValue),
                },
              },
              isSaved: isRuleSaved,
            });

        const isSaved = onSave(rule, isValidRule);
        // console.log("Saved...?", isSaved);
        // console.log("Saved rule", rule);

        isSaved
          ? toast.success(language?.general?.saved || "Rule saved")
          : toast.error(
              language?.messages?.toast?.rule?.invalidRule || "Error saving rule"
            );
      };

      const nonMagOperatorsSelect = [
        {
          optionName:
            language?.ruleComponent?.rule?.operatorSelect?.optionTitle ||
            "Select an Operator",
          optionValue: "-1",
        },
        {
          optionName: "<",
          optionValue: Operators.LT,
        },
        {
          optionName: "<=",
          optionValue: Operators.LTE,
        },
        {
          optionName: "=",
          optionValue: Operators.EQ,
        },
        {
          optionName: "!=",
          optionValue: Operators.NEQ,
        },
        {
          optionName: ">",
          optionValue: Operators.GT,
        },
        {
          optionName: ">=",
          optionValue: Operators.GTE,
        },
      ];
      const magOperatorsSelect = [
        {
          optionName:
            language?.ruleComponent?.rule?.operatorSelect?.optionTitle ||
            "Select an Operator",
          optionValue: "-1",
        },
        {
          optionName:
            language?.ruleComponent?.rule?.operatorSelect?.options?.open || "Open",
          optionValue: Operators.OPEN,
        },
        {
          optionName:
            language?.ruleComponent?.rule?.operatorSelect?.options?.close || "Close",
          optionValue: Operators.CLOSED,
        },
        {
          optionName:
            language?.ruleComponent?.rule?.operatorSelect?.options?.either || "Either",
          optionValue: Operators.EITHER,
        },
      ];

      const extractParameters = (obj: any) => {
        const elemIDs: any = [];
        for (const key in obj) {
          if (Array.isArray(obj[key])) {
            obj[key].forEach((item: any) => {
              if (item.elemID) {
                elemIDs.push(`${key}.${item.elemID}`);
              }
            });
          }
        }

        return elemIDs;
      };
      const currentDevice = groupDevices?.find((device) => device.eui === deviceEUI);
      const parameters = extractParameters(currentDevice?.configuration);
      const hysteresisModeOptions = [
        {
          name:
            language?.ruleComponent?.rule?.hysteresis?.hysteresisModeSelect?.options
              ?.none || "None",
          value: HysteresisMode.NONE,
        },
        {
          name:
            language?.ruleComponent?.rule?.hysteresis?.hysteresisModeSelect?.options
              ?.absolute || "Absolute",
          value: HysteresisMode.ABSOLUTE,
        },
        {
          name:
            language?.ruleComponent?.rule?.hysteresis?.hysteresisModeSelect?.options
              ?.percentage || "Percentage",
          value: HysteresisMode.PERCENTAGE,
        },
      ];
      const handleDeviceChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const { value } = event.target;
        setDeviceEUI(value);
        setParameter("");
        setOperator(Number("-1"));
        setThreshold("");
        setHysteresisMode(0);
        setHysteresisValue("");
        setIsRuleSaved(false);
      };
      const handleParameterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const { value } = event.target;
        setParameter(value);
        setOperator(Number("-1"));
        setThreshold("");
        setHysteresisMode(0);
        setHysteresisValue("");
        setIsRuleSaved(false);
      };
      const handleOperatorChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const { value } = event.target;
        setOperator(Number(value));
        setThreshold("");
        setIsRuleSaved(false);
      };
      const handleThresholdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setThreshold(value);
        setIsRuleSaved(false);
      };
      const handleThresholdFocus = (event: React.ChangeEvent<HTMLInputElement>) => {
        setThreshold("");
        setIsRuleSaved(false);
      };
      const handleHysteresisModeChange = (
        event: React.ChangeEvent<HTMLSelectElement>
      ) => {
        const { value } = event?.target;
        Number(value) === 0 && setHysteresisValue("");
        setHysteresisMode(Number(value));
        setIsRuleSaved(false);
      };
      const handleHysteresisValueChange = (
        event: React.ChangeEvent<HTMLInputElement>
      ) => {
        const { value } = event?.target;
        setHysteresisValue(value);
        setIsRuleSaved(false);
      };
      const handleHysteresisValueFocus = (event: React.ChangeEvent<HTMLInputElement>) => {
        setHysteresisValue("");
        setIsRuleSaved(false);
      };

      return (
        <form>
          <div className=" relative mb-6 bg-gray-100 p-10 rounded-lg mt-10">
            <CiCircleRemove
              size={30}
              color="grey"
              className="absolute top-2 right-2 cursor-pointer"
              onClick={() => removeRule(ruleId || "")}
            />
            <>
              <div className="flex items-center w-full">
                <select
                  className="border border-gray-300 rounded p-2 mr-4 mb-4 w-1/2 max-w-full max-h-20 overflow-y-auto"
                  id="deviceEUI"
                  name="deviceEUI"
                  value={deviceEUI}
                  onChange={handleDeviceChange}
                >
                  <option className="w-1/2 max-w-full" value="">
                    {language?.ruleComponent?.deviceSelect?.optionTitle ||
                      "Select a device"}
                  </option>
                  {groupDevices.length ? (
                    groupDevices.map((device: Device) => {
                      return (
                        <option
                          key={device.id}
                          value={device.eui}
                          className="w-1/2 max-w-full"
                        >
                          {device.name}
                        </option>
                      );
                    })
                  ) : (
                    <option className="w-1/2 max-w-full" value="">
                      {language?.ruleComponent?.deviceSelect?.noDeviceFound ||
                        "No device found"}
                    </option>
                  )}
                </select>
              </div>{" "}
            </>
            {deviceEUI && (
              <>
                <label className="block text-gray-700 font-bold mb-0">
                  {" "}
                  {language?.ruleComponent?.rule.heading || "Condition"}
                </label>
                <div className="flex items-center w-full">
                  <div className="flex flex-wrap items-center bg-gray-100 m-2 w-full md:w-9/12 p-0 rounded-lg">
                    <label className="block text-gray-700 font-bold mb-2 mr-4">
                      {language?.ruleComponent?.rule.paramaterSelect.label || "If"}
                    </label>
                    <select
                      className="border border-gray-300 rounded p-2 mr-4 mb-2"
                      name="parameter"
                      value={parameter}
                      onChange={handleParameterChange}
                    >
                      <option value="">
                        {language?.ruleComponent?.rule.paramaterSelect.optionTitle ||
                          "Select a parameter"}
                      </option>
                      {parameters.map((parameter: string) => {
                        return (
                          <option key={parameter} value={parameter}>
                            {parameter}
                          </option>
                        );
                      })}
                    </select>
                    {parameter && (
                      <select
                        className=" border border-gray-300 rounded p-2 mr-4 mb-2 w-20 h-10"
                        name="operator"
                        value={operator}
                        onChange={handleOperatorChange}
                      >
                        {parameter.startsWith("sw_") ? (
                          magOperatorsSelect.map((operator) => (
                            <option value={operator.optionValue}>
                              {operator.optionName}
                            </option>
                          ))
                        ) : !parameter.startsWith("sw_") ? (
                          nonMagOperatorsSelect.map((operator) => (
                            <option value={operator.optionValue}>
                              {operator.optionName}
                            </option>
                          ))
                        ) : (
                          <option value={"-1"}>
                            {language?.ruleComponent?.rule?.paramaterSelect
                              ?.optionTitle || "Select a parameter"}
                          </option>
                        )}
                      </select>
                    )}
                    <span className="mr-4 mb-2"></span>
                    {parameter !== "" && !parameter.startsWith("sw_") && (
                      <>
                        <input
                          type="number"
                          name="threshold"
                          value={threshold}
                          disabled={operator === Number("-1")}
                          onChange={handleThresholdChange}
                          onFocus={handleThresholdFocus}
                          className="border border-gray-300 rounded p-2 mr-4 mb-2 w-20"
                        />
                        <span className="mr-4 ml-4 mb-2">
                          {" "}
                          {language?.ruleComponent?.rule?.hysteresis?.label ||
                            "with hysteresis"}
                        </span>
                        <select
                          value={hysteresisMode}
                          onChange={handleHysteresisModeChange}
                          className=" border border-gray-300 rounded p-2 mr-4 mb-2"
                        >
                          {hysteresisModeOptions.map((hysteresis) => (
                            <option key={hysteresis.name} value={hysteresis.value}>
                              {hysteresis.name}
                            </option>
                          ))}
                        </select>
                        <input
                          type="number"
                          name="hysteresisValue"
                          disabled={hysteresisMode === 0}
                          value={hysteresisValue}
                          onChange={handleHysteresisValueChange}
                          onFocus={handleHysteresisValueFocus}
                          className="border border-gray-300 rounded p-2 mr-4 mb-2 w-20"
                        />
                      </>
                    )}
                  </div>
                </div>
              </>
            )}
            {isValidRule ? (
              <IoIosCheckmarkCircle className="text-green-500 absolute buttom-2 left-2 text-lg" />
            ) : (
              <MdOutlineError className="text-red-500 absolute buttom-2 left-2 text-lg" />
            )}
            {/* <button
              className="absolute bottom-2 right-2 bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg shadow-lg transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-green-400"
              onClick={handleSaveRule}
            >
              {language?.general?.save || "Save"}
            </button> */}

            {isRuleSaved ? (
              <button
                className="absolute bottom-2 right-2 bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg shadow-lg transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-green-400"
                onClick={handleSaveRule}
              >
                {language?.general?.saved || "Saved"}
              </button>
            ) : (
              <button
                className="absolute bottom-2 right-2 bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-lg shadow-lg transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-red-400"
                onClick={handleSaveRule}
              >
                {language?.general?.unsaved || "Unsaved"}
              </button>
            )}
          </div>
        </form>
      );
    };

    return (
      <>
        {rules?.map((rule) => (
          <RuleForm rule={rule} onSave={saveRule} key={rule.id} />
        ))}
        <button
          onClick={addRule}
          className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg shadow-lg transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-green-400"
        >
          {language?.ruleComponent?.rule?.addRuleBtn || "Add Rule"}
        </button>
      </>
    );
  }
);

export default NotificationRule;
