import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export enum Operators {
  LT = 0,
  LTE = 1,
  EQ = 2,
  NEQ = 3,
  GT = 4,
  GTE = 5,
  OPEN = 6,
  CLOSED = 7,
  EITHER = 8,
}

export enum HysteresisMode {
  NONE = 0,
  ABSOLUTE = 1,
  PERCENTAGE = 2,
}
interface Hysteresis {
  value: number;
  mode: HysteresisMode;
}
export interface RulesSpec {
  id?: string;
  notificationID?: string;
  device_eui?: string;
  parameter?: string;
  operator?: Operators | string;
  threshold?: number | string;
  hysteresis?: Hysteresis;
}

// type NotificationStatus struct {
// 	Active      bool      `json:"active" bson:"active" binding:"required"`
// 	Current     bool      `json:"current" bson:"current,omitempty"`           // Current State of the evaluation of the rules
// 	Triggered   bool      `json:"triggered" bson:"triggered,omitempty"`       // Current State Notification, also taking into account the LatchedOn setting. Cleared v
// 	TriggeredAt time.Time `json:"triggered_at" bson:"triggered_at,omitempty"` // timestamp of when the state changed from not triggered to triggered
// 	LastTrigger time.Time `json:"last_trigger" bson:"last_trigger,omitempty"` // timestamp of the last time the rule was assessed and evaluated to true
// 	EvaluatedAt time.Time `json:"evaluated_at" bson:"evaluated_at,omitempty"` // last time the rules were evalutated
// }

export interface Status {
  active: boolean;
  current?: boolean;
  triggered?: boolean;
  triggered_at?: Date;
  last_trigger?: Date;
  evaluated_at?: Date;
}
export interface RuleStatus {
  changed_at?: Date;
  state?: boolean;
  value?: number;
  previousState?: boolean;
}
export interface Rule {
  id?: string;
  spec?: RulesSpec;
  status?: RuleStatus;
  isSaved?: boolean;
}
export interface Email {
  subject: string | null;
  body: string | null;
  trigger: Date;
}
export interface Configuration {
  repeat_interval: number;
  hold_on: number;
  hold_off: number;
  assert_on: boolean;
  latch_on: boolean;
}

export interface NotificationState {
  id?: string;
  group_id: string;
  name: string;
  users: [{ [key: string]: string }];
  rules: Rule[];
  email: Email;
  configuration?: Configuration;
  status?: Status;
}

const initialState: NotificationState[] = [];

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setNotifications: (state, action: PayloadAction<NotificationState[]>) =>
      (state = action.payload),
    removeNotification: (state, action: PayloadAction<string>) => {
      return state.filter((notification) => notification.id !== action.payload);
    },
  },
});

export const { setNotifications, removeNotification } = notificationSlice.actions;
export const selectNotifications = (state: RootState) => state.notifications;

export default notificationSlice.reducer;
